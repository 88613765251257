import React, { Component } from "react";
import { graphql } from "gatsby";

// Bootstrap components
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Card from "react-bootstrap/Card"

// Persosa components
import App from "../components/App";
import Markdown from "../components/Markdown"
import Footer from "../components/Footer"
import Navigation from "../components/Navigation"
import SEO from "../components/seo";

// Other assets
import PhoneIcon from "../assets/images/icon-phone-purple.svg";
import LocationIcon from "../assets/images/icon-location-purple.svg";
import EmailIcon from "../assets/images/icon-email-purple.svg";

import persosaImage from "../assets/images/persosa-og.jpg";

class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      btnMessage: 'Send Message',
      optin: false,
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onChecked = e => {
    this.setState({ [e.target.name]: e.target.checked })
  }


  render() {

    const navbar = this.props.data.allContentfulNavbar.edges[0].node;
    const footerData = this.props.data.allContentfulFooter.edges[0].node;
    const {
      title,
      description,
      formHeading,
      backgroundImage,
      contactInfo,
      zapierHook,
      formKey,
      formDescription
    } = this.props.data.allContentfulContactPage.edges[0].node;

    const {
      firstName,
      lastName,
      email,
      message,
      optin
    } = this.state;

    const validateEmail = (email) => {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    const isDisabled = () => {
      if (firstName.length > 0
          && lastName.length > 0
          && validateEmail(email)
          && message.length > 0) {
        return false
      } else {
        return true
      }
    }

    const submit = e => {
      e.preventDefault()
      fetch(`${zapierHook}`, {
        method: 'POST',
        body: JSON.stringify({ firstName, lastName, email, message, optin, formKey }),
      }).then(() => {
        document.getElementById('submitBtn').disabled = true;
        this.setState({btnMessage: 'Thank you!'});
      }).catch((err) => {
        console.error(err)
      })
    }

    return (
      <App navbar={navbar}>
      <SEO title={title} description={description.description} image={persosaImage}/>

      <div className="contact">
        <div className="hero contact-hero">

					<div className="hero-image"  style={{'backgroundImage': `url(${backgroundImage.file.url})` }}></div>

				<div className="hero-overlay"></div>

					<Container fluid className="hero-nav">
						<Navigation menus={navbar.menus} />
					</Container>

          <Container>
            <Row>
              <Col xl={{ span: 6, offset: 1}} lg={{ span: 7, offset: 0}}>
								<Card className="contact-form">
									<Card.Body>
		                <Form>
		                  <div className="text-center">
		                    <h1>{formHeading}</h1>
		                    <Markdown content={formDescription} />
		                  </div>

		                  <Form.Group>
		                    <Row noGutters>
		                      <Col md="6">
														<Form.Control name="firstName" value={firstName} onChange={this.onChange} type="text" placeholder="First Name" />
													</Col>
		                      <Col md="6">
														<Form.Control name="lastName" value={lastName} onChange={this.onChange} type="text" placeholder="Last Name" />
													</Col>
		                    </Row>
											</Form.Group>
											<Form.Group>
		                    <Form.Control name="email" type="email" onChange={this.onChange} placeholder="Work email" />
											</Form.Group>
											<Form.Group>
												<Form.Control name="message" as="textarea" onChange={this.onChange} />
											</Form.Group>
											<Form.Group>
			                    <Form.Check
														custom
			                      name="optin"
														id="optin"
			                      label="Keep me updated with Persosa"
			                      type="checkbox"
			                      value={optin}
			                      onChange={this.onChecked}
			                    />
		                  </Form.Group>
	                  	<Button id="submitBtn" className="w-100" onClick={submit} disabled={isDisabled()} variant="primary">{this.state.btnMessage}</Button>
										</Form>
									</Card.Body>
								</Card>
              </Col>
              <Col lg={{ span: 4, offset: 1}} className="contact-info-wrapper">
                {contactInfo.map((item, key) => (
                  <div className="contact-info-block" key={key}>
                    <h4>
											{item.contactType === "Phone" &&
												<img src={PhoneIcon} alt="Phone icon" />
											}
											{item.contactType === "Address" &&
												<img src={LocationIcon} alt="Location icon" />
											}
											{item.contactType === "Email" &&
												<img src={EmailIcon} alt="Email icon" />
											}
											{item.title}
										</h4>
                    <Markdown content={item.contactDetails} />
                  </div>
                ))}
              </Col>
            </Row>
          </Container>

        </div>
        <Footer footer={footerData} />
      </div>
      </App>

    )
  }
}

export default Contact

export const pageQuery = graphql`
query contactQuery {
  allContentfulContactPage {
    edges {
      node {
        id
        title
        slug
        description {
          description
        }
        backgroundImage {
          file {
            url
          }
        }
        formHeading
        zapierHook
        formKey
        formDescription {
          json
        }
        contactInfo {
          title
          contactType
          contactDetails {
            id
            json
          }
        }
      }
    }
  }
  allContentfulNavbar {
    edges {
      node {
        id
        menus {
          title
          titleLink
          links {
            url
            title
          }
        }
      }
    }
  }
  allContentfulFooter {
    edges {
      node {
        id
        menus {
          title
          titleLink
          links {
            url
            title
          }
        }
        colophon
      }
    }
  }
}
`
